import React, { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig'; 
import { setAuthToken } from '../../utils/auth';
import { QRCodeSVG } from 'qrcode.react';
import { Zap, Users, LinkIcon, QrCode, ExternalLink, Download, AlertCircle, GiftIcon, BellRing, Smartphone, Settings, ChevronLeft, ChevronRight } from 'lucide-react';
import '../../styles/HomeTab.css';

const HomeTab = () => {
  const [vendorData, setVendorData] = useState(null);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstProgram, setFirstProgram] = useState(null);
  const [copyMessage, setCopyMessage] = useState('');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const handleNextStep = () => {
    setCurrentStepIndex(prev => Math.min(prev + 1, platformSteps.length - 1));
  };

  const handlePrevStep = () => {
    setCurrentStepIndex(prev => Math.max(prev - 1, 0));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          setAuthToken(token);
        }

        setIsLoading(true);
        setError(null);
        
        const [profileRes, customersRes, programsRes] = await Promise.all([
          axios.get('/api/vendors/profile'),
          axios.get('/api/vendors/reports/registrations?startDate=2000-01-01'),
          axios.get('/api/vendors/dashboard-programs')
        ]);

        setVendorData(profileRes.data);
        setTotalCustomers(customersRes.data.total);
        if (programsRes.data.length > 0) {
          setFirstProgram(programsRes.data[0]);
        }
      } catch (err) {
        console.error('Error fetching vendor data:', err);
        if (err.response?.status === 401) {
          setError('Session expired. Please refresh the page or log in again.');
        } else {
          setError('Failed to load dashboard data. Please try again.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const downloadQRCode = (id, filename) => {
    const svg = document.getElementById(id);
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    
    const scaledSvg = svgData.replace(
      /width="200" height="200"/,
      'width="1000" height="1000"'
    );
    
    const svgBlob = new Blob([scaledSvg], { type: 'image/svg+xml;charset=utf-8' });
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = 1000;
      canvas.height = 1000;
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, 1000, 1000);

      const pngUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `${filename}.png`;
      downloadLink.href = pngUrl;
      downloadLink.click();
    };

    const url = URL.createObjectURL(svgBlob);
    img.src = url;
  };

  const vendorUrl = `${window.location.origin}/vendor/${vendorData?.sub_url}`;
  
  const platformSteps = [
    {
      step: 1,
      title: "Digital Onboarding",
      description: "Get started in minutes with your personalized digital loyalty program. We provide you with unique QR codes and a custom business link.",
      icon: GiftIcon,
      highlight: "Quick Setup"
    },
    {
      step: 2,
      title: "Customer Collection",
      description: "Customers scan your QR code to join your loyalty program. No app downloads required - it works directly in their browser.",
      icon: Smartphone,
      highlight: "Seamless Customer Experience"
    },
    {
      step: 3,
      title: "Reward Management",
      description: "Set up your stamps program, define rewards, and customize special offers. Full control over your loyalty structure.",
      icon: Settings,
      highlight: "Flexible Rewards"
    },
    {
      step: 4,
      title: "Customer Engagement",
      description: "Keep customers coming back with push inbox messages, email updates, and digital menus/specials.",
      icon: BellRing,
      highlight: "Multi-Channel Communication"
    },
    {
      step: 5,
      title: "Insights & Analytics",
      description: "Track performance, understand customer behavior, and optimize your program based on real data.",
      icon: Users,
      highlight: "Data-Driven Decisions"
    },
    {
      step: 6,
      title: "Growth & Scale",
      description: "Expand your customer base, increase retention, and grow your business with our integrated marketing tools.",
      icon: Zap,
      highlight: "Business Growth"
    }
  ];

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyMessage('Copied to clipboard');
      setTimeout(() => setCopyMessage(''), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        {/* Header Section */}
        <div className="header-section">
          <div className="header-content">
            <h1 className="header-title">
              Welcome, {vendorData?.business_name}
            </h1>
            <span className="plan-badge">
              Free Plan
            </span>
          </div>
          
          <div className="stats-grid">
            <div className="stats-card">
              <div className="stats-card-content">
                <div className="icon-container">
                  <Users className="stats-icon" />
                </div>
                <div>
                  <p className="stats-label">Total Customers</p>
                  <p className="stats-value">{totalCustomers}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* QR Codes Section */}
        <div className="qr-grid">
          {/* Business Link QR */}
          <div className="qr-card">
            <h2 className="qr-title">
              <LinkIcon className="qr-title-icon" />
              Your Business Link
            </h2>
            <div className="qr-content">
              <div className="qr-wrapper">
                <QRCodeSVG 
                  id="business-qr-code"
                  value={vendorUrl}
                  size={200}
                  level="H"
                  includeMargin={true}
                  className="qr-code"
                />
                <Tooltip text="">
                  <button
                    onClick={() => downloadQRCode('business-qr-code', 'business-qr')}
                    className="download-button"
                    aria-label="Download QR Code"
                  >
                    <Download className="download-icon" />
                  </button>
                </Tooltip>
              </div>
              <p className="qr-description">
                Print this QR code and display it at your business location for customers to scan
              </p>
              <div className="url-container">
                <div className="url-wrapper">
                  <input 
                    type="text" 
                    value={vendorUrl} 
                    readOnly 
                    className="url-input"
                    aria-label="Business URL"
                  />
                  <button 
                    onClick={() => copyToClipboard(vendorUrl)}
                    className="url-action-button"
                    aria-label="Copy URL to clipboard"
                  >
                    <LinkIcon className="url-action-icon" />
                  </button>
                  <a 
                    href={vendorUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="url-action-button"
                    aria-label="Open business URL in new tab"
                  >
                    <ExternalLink className="url-action-icon" />
                  </a>
                  
                </div>
                {copyMessage && (
                  <p className="copy-message" aria-live="polite">
                    {copyMessage}
                  </p>
                )}
              </div>

              <p className="qr-description">
                OR share this link with customers directly on web, social media and print
              </p>
              
            </div>
          </div>

          {/* Loyalty Program QR */}
          {firstProgram && (
            <div className="qr-card">
              <h2 className="qr-title">
                <QrCode className="qr-title-icon" />
                Loyalty Program QR Code
              </h2>
              <div className="qr-content">
                <div className="qr-wrapper">
                  <QRCodeSVG
                    id="loyalty-qr-code"
                    value={JSON.stringify({
                      programId: firstProgram.id,
                      type: 'visit_checkin'
                    })}
                    size={200}
                    level="H"
                    includeMargin={true}
                    className="qr-code"
                  />
                  <Tooltip text="">
                    <button
                      onClick={() => downloadQRCode('loyalty-qr-code', 'loyalty-program-qr')}
                      className="download-button"
                      aria-label="Download Loyalty Program QR Code"
                    >
                      <Download className="download-icon" />
                    </button>
                  </Tooltip>
                </div>
                <p className="qr-description">
                  Print this QR code for customer check-ins at your location.
                </p>
                <p className="qr-description">
                To test, visit your business link and scan this code from the loyalty card to earn stamps.
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Platform Walkthrough */}
        <div className="walkthrough-card">
          <h2 className="walkthrough-title">
            <Zap className="walkthrough-title-icon" />
            How It Works
          </h2>

          <div className="walkthrough-content">
            {/* Progress Bar */}
            <div className="progress-bar-container">
              <div 
                className="progress-bar"
                style={{ width: `${((currentStepIndex + 1) / platformSteps.length) * 100}%` }}
                aria-hidden="true"
              />
            </div>

            {/* Step Counter */}
            <div className="step-counter">
              Step {currentStepIndex + 1} of {platformSteps.length}
            </div>

            {/* Step Content */}
            <div className="step-content">
              <div className="step-navigation">
                <button 
                  onClick={handlePrevStep}
                  disabled={currentStepIndex === 0}
                  className={`nav-button ${currentStepIndex === 0 ? 'nav-button-disabled' : ''}`}
                  aria-label="Previous step"
                >
                  <ChevronLeft className="nav-icon" />
                </button>

                <div className="step-details">
                  <div className="step-icon-wrapper">
                    {React.createElement(platformSteps[currentStepIndex].icon, { className: 'step-icon' })}
                  </div>
                  <h3 className="step-title">
                    {platformSteps[currentStepIndex].title}
                  </h3>
                  <p className="step-description">
                    {platformSteps[currentStepIndex].description}
                  </p>
                  <span className="step-highlight">
                    {platformSteps[currentStepIndex].highlight}
                  </span>
                </div>

                <button 
                  onClick={handleNextStep}
                  disabled={currentStepIndex === platformSteps.length - 1}
                  className={`nav-button ${currentStepIndex === platformSteps.length - 1 ? 'nav-button-disabled' : ''}`}
                  aria-label="Next step"
                >
                  <ChevronRight className="nav-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const LoadingSkeleton = () => {
  return (
    <div className="p-4 sm:p-6 lg:p-8 max-w-7xl mx-auto space-y-6 sm:space-y-8 animate-pulse">
      <div className="bg-gray-200 h-32 rounded-lg"></div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-gray-200 h-24 rounded-lg"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-200 h-64 rounded-lg"></div>
        <div className="bg-gray-200 h-64 rounded-lg"></div>
      </div>
      <div className="bg-gray-200 h-96 rounded-lg"></div>
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg shadow-md my-4">
      <div className="flex items-center">
        <AlertCircle className="w-6 h-6 mr-2" />
        <p className="font-medium">{message}</p>
      </div>
    </div>
  );
};

const Tooltip = ({ children, text }) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        {text}
      </div>
    </div>
  );
};

export default HomeTab;