import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig';

function TestAccountsManager() {
  const [testAccounts, setTestAccounts] = useState({ testCustomers: [], testVendors: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState(null);

  useEffect(() => {
    fetchTestAccounts();
  }, []);

  const fetchTestAccounts = async () => {
    try {
      const response = await axios.get('/api/admin/test-accounts');
      setTestAccounts(response.data);
    } catch (error) {
      console.error('Error fetching test accounts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchError(null);
    
    // Clear results if search is empty
    if (!searchTerm.trim()) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await axios.get('/api/admin/search-accounts', {
        params: { q: searchTerm.trim() }
      });

      if (response.data.results) {
        setSearchResults(response.data.results);
        if (response.data.results.length === 0) {
          setSearchError('No results found');
        }
      }
    } catch (error) {
      console.error('Error searching accounts:', error);
      setSearchError(error.response?.data?.error || 'Error searching accounts');
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const toggleTestStatus = async (id, type) => {
    try {
      await axios.put(`/api/admin/${type}/${id}/toggle-test`);
      await fetchTestAccounts();
      
      // Remove toggled account from search results
      setSearchResults(prev => prev.filter(result => result.id !== id));
      
      // Show success message
      alert('Test status updated successfully');
    } catch (error) {
      console.error('Error toggling test status:', error);
      alert(error.response?.data?.error || 'Error updating test status');
    }
  };

  if (isLoading) {
    return <div className="loading">Loading test accounts...</div>;
  }

  return (
    <div className="test-accounts-manager">
      <h2>Test Accounts Manager</h2>
      
      <form onSubmit={handleSearch} className="search-section">
        <div className="search-input-group">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by name, email, or phone"
            disabled={isSearching}
            className={searchError ? 'error' : ''}
          />
          <button type="submit" disabled={isSearching}>
            {isSearching ? 'Searching...' : 'Search'}
          </button>
        </div>
        {searchError && <div className="search-error">{searchError}</div>}
      </form>

      {searchResults.length > 0 && (
        <div className="search-results">
          <h3>Search Results</h3>
          <div className="results-grid">
            {searchResults.map((result) => (
              <div key={`${result.type}-${result.id}`} className="result-item">
                <div className="result-info">
                  <span className="result-name">{result.name}</span>
                  <span className="result-type">{result.type}</span>
                  <small className="result-email">{result.email}</small>
                </div>
                <button
                  onClick={() => toggleTestStatus(
                    result.id,
                    result.type === 'vendor' ? 'vendors' : 'customers'
                  )}
                  className="mark-test-btn"
                >
                  Mark as Test
                </button>
              </div>
            ))}
          </div>
        </div>
      )}


    <div className="test-accounts-section">
        <div className="test-customers">
          <h3>Test Customers ({testAccounts.testCustomers.length})</h3>
          {testAccounts.testCustomers.length === 0 ? (
            <p>No test customers</p>
          ) : (
            testAccounts.testCustomers.map(customer => (
              <div key={customer.id} className="test-account-item">
                <span>{customer.full_name}</span>
                <button
                  onClick={() => toggleTestStatus(customer.id, 'customers')}
                  className="remove-test-btn"
                >
                  Remove Test Status
                </button>
              </div>
            ))
          )}
        </div>

        <div className="test-vendors">
          <h3>Test Vendors ({testAccounts.testVendors.length})</h3>
          {testAccounts.testVendors.length === 0 ? (
            <p>No test vendors</p>
          ) : (
            testAccounts.testVendors.map(vendor => (
              <div key={vendor.id} className="test-account-item">
                <span>{vendor.business_name}</span>
                <button
                  onClick={() => toggleTestStatus(vendor.id, 'vendors')}
                  className="remove-test-btn"
                >
                  Remove Test Status
                </button>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default TestAccountsManager;