import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
// import '../styles/SetupWizard.css';
import '../styles/VendorRegister.css';

const BUSINESS_TYPES = [
  'Restaurant',
  'Cafe',
  'Retail Store',
  'Salon',
  'Spa',
  'Other'
];

export default function SetupWizard() {
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');									 
  const [formData, setFormData] = useState({
    businessType: '',
    logo: null,
    programName: '',
    stampsRequired: '',
    reward: '',
    notificationEmail: '',
    address: '',
    phone: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [previewLogo, setPreviewLogo] = useState(null);
  const navigate = useNavigate();

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, logo: file });
      setPreviewLogo(URL.createObjectURL(file));
    }
  };

  const validateStep = (stepNumber) => {
    switch(stepNumber) {
      case 1:
        return !!formData.businessType;
      case 2:
        return true; // Logo is optional
      case 3:
        return !!formData.programName && 
               !!formData.stampsRequired && 
               !!formData.reward;
      case 4:
        return !!formData.notificationEmail && 
               !!formData.address && 
               !!formData.phone;
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // First update vendor profile
      const profileFormData = new FormData();
      profileFormData.append('business_type', formData.businessType);
      profileFormData.append('phone', formData.phone);
      profileFormData.append('address', formData.address);
      profileFormData.append('notification_email', formData.notificationEmail);
      if (formData.logo) {
        profileFormData.append('logo_url', formData.logo);
      }

      await axios.put('/api/vendors/profile', profileFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Then create loyalty program
      await axios.post('/api/vendors/programs', {
        name: formData.programName,
        stamps_required: parseInt(formData.stampsRequired),
        description: formData.reward,
        about: `${formData.programName} - Collect ${formData.stampsRequired} stamps to earn ${formData.reward}`,
        active: true
      });

        // Navigate to vendor dashboard home tab
        navigate('/vendor/dashboard', { 
        state: { activeTab: 'home', setupComplete: true }
        });
    } catch (error) {
      console.error('Setup error:', error);
      setError(error.response?.data?.error || 'Error completing setup');
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <div className="wizard-step">
            <h2>What type of business do you run?</h2>
            <div className="business-types-grid">
              {BUSINESS_TYPES.map(type => (
                <button
                  key={type}
                  className={`business-type-btn ${formData.businessType === type ? 'selected' : ''}`}
                  onClick={() => setFormData({...formData, businessType: type})}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
        );

      case 2:
        return (
          <div className="wizard-step">
            <h2>Upload your business logo</h2>
            <div className="logo-upload-container">
              {previewLogo ? (
                <img src={previewLogo} alt="Logo preview" className="logo-preview" />
              ) : (
                <div className="logo-placeholder">
                  <span>Click to upload logo</span>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoChange}
                className="logo-input"
              />
            </div>
          </div>
        );

      case 3:
        return (
          <div className="wizard-step">
            <h2>Set up your loyalty program</h2>
            <div className="form-group">
              <input
                type="text"
                placeholder="Program Name"
                value={formData.programName}
                onChange={(e) => setFormData({...formData, programName: e.target.value})}
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                placeholder="Stamps Required for reward"
                value={formData.stampsRequired}
                onChange={(e) => setFormData({...formData, stampsRequired: e.target.value})}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Reward Description"
                value={formData.reward}
                onChange={(e) => setFormData({...formData, reward: e.target.value})}
              />
            </div>
          </div>
        );

      case 4:
        return (
          <div className="wizard-step">
            <h2>Contact Information</h2>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email for booking notifications"
                value={formData.notificationEmail}
                onChange={(e) => setFormData({...formData, notificationEmail: e.target.value})}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Business physical address"
                value={formData.address}
                onChange={(e) => setFormData({...formData, address: e.target.value})}
              />
            </div>
            <div className="form-group">
              <input
                type="tel"
                placeholder="Business phone number"
                value={formData.phone}
                onChange={(e) => setFormData({...formData, phone: e.target.value})}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="setup-wizard-container">
      <div className="wizard-card">
	      {error && <div className="error-message">{error}</div>}											   
        <div className="progress-bar">
          {[1, 2, 3, 4].map(num => (
            <div
              key={num}
              className={`progress-step ${step >= num ? 'active' : ''}`}
            />
          ))}
        </div>

        {renderStep()}

        <div className="wizard-buttons">
          {step > 1 && (
            <button
              onClick={() => setStep(step - 1)}
              className="prev-button"
            >
              Previous
            </button>
          )}
          {step < 4 ? (
            <button
			   onClick={() => {
                if (validateStep(step)) {
                  setStep(step + 1);
                  setError('');
                } else {
                  setError('Please complete all required fields');
                }
              }}
              className="next-button"
            >
              Next
            </button>
          ) : (
            <button
			    onClick={() => {
                if (validateStep(step)) {
                  handleSubmit();
                } else {
                  setError('Please complete all required fields');
                }
              }}
              className="finish-button"
              disabled={isLoading}
            >
              {isLoading ? 'Finishing Setup...' : 'Finish Setup'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}