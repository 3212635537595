import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/VendorRegister.css';

export default function VendorRegister() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    businessName: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
  
    try {
      const result = await axios.post('/api/auth/register', {
        email: formData.email,
        password: formData.password,
        name: formData.businessName,
        role: 'vendor'
      });
  
      // Set auth token and redirect
      localStorage.setItem('token', result.data.token);
      setAuthToken(result.data.token);
      
      // Navigate to setup wizard
      navigate('/vendor/setup-wizard', { 
        state: { isNewRegistration: true }
      });
    } catch (err) {
      setError(err.response?.data?.error || 'Registration failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="vendor-register-container">
      <div className="register-card">
        <h1>Create Your Vendor Account</h1>
        <p className="subtitle">Start growing your business with digital loyalty programs</p>

        <form onSubmit={handleSubmit}>
          {error && <div className="error-message">{error}</div>}
          
          <div className="form-group">
            <input
              type="email"
              placeholder="Business Email"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              placeholder="Password"
              value={formData.password}
              onChange={(e) => setFormData({...formData, password: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              placeholder="Business Name"
              value={formData.businessName}
              onChange={(e) => setFormData({...formData, businessName: e.target.value})}
              required
            />
          </div>

          <button type="submit" className="register-button" disabled={isLoading}>
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </button>
        </form>
      </div>
    </div>
  );
}