import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Login from './components/Login';
import Register from './components/Register';
import CustomerDashboard from './components/CustomerDashboard';
import VendorDashboard from './components/VendorDashboard';
import AllVendorsPage from './components/AllVendorsPage';
import VendorPublicPage from './components/VendorPublicPage';
import CustomerLoyaltyCards from './components/CustomerLoyaltyCards';
import ProgramLoyaltyCard from './components/ProgramLoyaltyCard';
import LoyaltyCard from './components/LoyaltyCard';
import RegisterPWA from './components/RegisterPWA';
import ProtectedRoute from './components/ProtectedRoute';
import LandingPage from './components/LandingPage';
import ApproveBooking from './components/ApproveBooking';
import PasswordReset from './components/PasswordReset';
import EmailChange from './components/EmailChange';
import AdminDashboard from './components/AdminDashboard';
import NotFound from './components/NotFound';
import VendorRegister from './components/VendorRegister';
import SetupWizard from './components/SetupWizard';
import ReviewCard from './components/ReviewCard';

const queryClient = new QueryClient();


// const CustomerLayout = () => (
//   <div>
//     <PushNotificationSubscription />
//     <Outlet />
//   </div>
// );

function AppContent() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingTimeout, setLoadingTimeout] = useState(false);

  useEffect(() => {
    const getCurrentSubUrl = () => {
      const pathParts = location.pathname.split('/');
      if (pathParts[1] === 'vendor' && pathParts[2]) {
        return pathParts[2];
      }
      return '';
    };

    const subUrl = getCurrentSubUrl();
    const link = document.querySelector("#manifest-placeholder");
    if (link && subUrl) {
      link.href = `/manifest.json?subUrl=${encodeURIComponent(subUrl)}`;
    }

    // Loading indicator logic
    const timeoutId = setTimeout(() => {
      setLoadingTimeout(true);
    }, 10000); // Show manual reload after 10 seconds

    window.addEventListener('load', () => {
      setIsLoading(false);
      clearTimeout(timeoutId);
    });

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('load', () => setIsLoading(false));
    };
  }, [location]);

  const handleManualReload = () => {
    window.location.reload();
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
        {loadingTimeout && (
          <button onClick={handleManualReload} className="manual-reload-btn">
            Reload Page
          </button>
        )}
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      {/* <Route path="/vendor/dashboard" element={<VendorDashboard />} /> */}

      <Route 
        path="/vendor/dashboard" 
        element={
          <ProtectedRoute>
            <VendorDashboard />
          </ProtectedRoute>
        } 
      />
    
      <Route path="/vendors" element={<AllVendorsPage />} />
      <Route path="/vendor/:subUrl" element={<VendorPublicPage />} />
      {/* <Route path="/" element={<Navigate to="/vendors" replace />} /> */}
      <Route path="/" element={<LandingPage />} />
      
        <Route path="/customer" element={<CustomerDashboard />} />
        <Route path="/my-loyalty-cards" element={<CustomerLoyaltyCards />} />
        <Route path="/vendor/:subUrl/program/:programId" element={<ProgramLoyaltyCard />} />
        <Route path="/loyalty-card/:programId" element={<LoyaltyCard />} />
        <Route path="/register/:subUrl" element={<RegisterPWA />} />
        <Route path="/approve-booking/:token" element={<ApproveBooking />} />
        <Route 
          path="/reset-password/:token" 
          element={
            <PasswordReset 
              isLoggedOut={true}
              isModal={false}
            />
          } 
        />
     <Route 
        path="/verify-email/:token" 
        element={<EmailChange isVerification={true} />} 
      />

      <Route 
        path="/admin/dashboard" 
        element={
          <ProtectedRoute allowedRoles={['admin']}>
            <AdminDashboard />
          </ProtectedRoute>
        } 
      />

    <Route path="/vendor/register" element={<VendorRegister />} />
    <Route path="/vendor/setup-wizard" element={
      <ProtectedRoute allowedRoles={['vendor']}>
        <SetupWizard />
              </ProtectedRoute>
        } />

    <Route path="/vendor/:subUrl/review" element={<ReviewCard />} />
    

    <Route path="*" element={<NotFound />} />

    </Routes>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppContent />
      </Router>
    </QueryClientProvider>
  );
}

export default App;