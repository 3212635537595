import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/LandingPage.css';
import EnquiryForm from './EnquiryForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../utils/axiosConfig';
import ErrorBoundary from './ErrorBoundary';

const FEATURED_VENDOR_URLS = ['cb']; // Hardcoded featured vendor subUrls

const LandingPageWithErrorBoundary = () => {
  return (
    <ErrorBoundary fallbackComponent={<LandingPageError />}>
      <LandingPage />
    </ErrorBoundary>
  );
};

// Add this error component
const LandingPageError = () => (
  <div className="landing-page-error">
    <h1>Something went wrong</h1>
    <p>We're having trouble loading the page</p>
    <button 
      onClick={() => window.location.reload()}
      className="retry-button"
    >
      Retry
    </button>
  </div>
);

const LandingPage = () => {
  const [showEnquiryForm, setShowEnquiryForm] = useState(false);
  const [featuredVendors, setFeaturedVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    // Add ID to body for landing page specific styles
    document.body.id = 'lp1';
  
    // Add meta tags to prevent caching
    const metaTags = [
      { httpEquiv: 'Cache-Control', content: 'no-cache, no-store, must-revalidate' },
      { httpEquiv: 'Pragma', content: 'no-cache' },
      { httpEquiv: 'Expires', content: '0' }
    ];
  
    metaTags.forEach(tag => {
      const meta = document.createElement('meta');
      meta.httpEquiv = tag.httpEquiv;
      meta.content = tag.content;
      document.head.appendChild(meta);
    });
  
    // Cleanup function
    return () => {
      // Remove the body ID when component unmounts
      document.body.id = '';
      
      // Cleanup meta tags
      metaTags.forEach(tag => {
        const meta = document.querySelector(`meta[http-equiv="${tag.httpEquiv}"]`);
        if (meta) meta.remove();
      });
    };
  }, []);

  // Add SEO meta tags useEffect
useEffect(() => {
  // SEO meta tags for landing page
  const metaTags = [
    // Update description for landing page
    { name: 'description', content: 'Boost customer loyalty with our easy-to-use digital loyalty program solution. Engage customers, increase retention, and grow your business.' },
    { name: 'keywords', content: 'loyalty program, customer retention, digital loyalty cards, business growth, customer rewards, loyalty app' },
    
    // OpenGraph Tags for landing page
    { property: 'og:title', content: 'My Local - Digital Loyalty Program Solution' },
    { property: 'og:description', content: 'Transform your business with our digital loyalty program. Increase customer retention and boost sales.' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: window.location.href },
    { property: 'og:image', content: `${window.location.origin}/mylocal192.png` },
    
    // Twitter Card Tags
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: 'My Local - Digital Loyalty Program Solution' },
    { name: 'twitter:description', content: 'Transform your business with our digital loyalty program. Increase customer retention and boost sales.' },
    { name: 'twitter:image', content: `${window.location.origin}/mylocal192.png` }
  ];

  // Update page title for landing page
  document.title = 'My Local - Digital Loyalty Program Solution';

  // Create and append meta tags
  metaTags.forEach(tag => {
    // First, remove any existing tag with same property/name to avoid duplicates
    const selector = tag.property ? 
      `meta[property="${tag.property}"]` : 
      `meta[name="${tag.name}"]`;
    const existingTag = document.querySelector(selector);
    if (existingTag) {
      existingTag.remove();
    }

    const meta = document.createElement('meta');
    if (tag.property) {
      meta.setAttribute('property', tag.property);
    } else {
      meta.setAttribute('name', tag.name);
    }
    meta.content = tag.content;
    document.head.appendChild(meta);
  });

  // Add schema markup
  const schemaScript = document.createElement('script');
  schemaScript.type = 'application/ld+json';
  schemaScript.text = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "My Local Loyalty Program",
    "applicationCategory": "BusinessApplication",
    "description": "Digital loyalty program solution for businesses to increase customer retention and boost sales",
    "operatingSystem": "Web",
  });
  document.head.appendChild(schemaScript);

  // Cleanup function
  return () => {
    // Remove meta tags when component unmounts
    metaTags.forEach(tag => {
      const selector = tag.property ? 
        `meta[property="${tag.property}"]` : 
        `meta[name="${tag.name}"]`;
      const meta = document.querySelector(selector);
      if (meta) meta.remove();
    });

    // Remove schema script
    const schema = document.querySelector('script[type="application/ld+json"]');
    if (schema) schema.remove();
  };
}, []); 
  


  // Add loading state management
  useEffect(() => {
    const loadContent = async () => {
      try {
        // Fetch featured vendors
        const vendorPromises = FEATURED_VENDOR_URLS.map(subUrl => 
          axios.get(`/api/vendors/${subUrl}`)
        );
        
        const responses = await Promise.all(vendorPromises);
        const vendors = responses.map(response => response.data)
          .filter(vendor => vendor);
        
        setFeaturedVendors(vendors);
      } catch (error) {
        console.error('Error fetching featured vendors:', error);
      } finally {
        setIsLoading(false);
        setContentLoaded(true);
      }
    };

    loadContent();

    // Add a fallback timer to ensure content shows even if loading fails
    const timer = setTimeout(() => {
      setIsLoading(false);
      setContentLoaded(true);
    }, 3000); // 3 second fallback

    return () => clearTimeout(timer);
  }, []);

  if (isLoading || !contentLoaded) {
    return (
      <div className="loading-container-lp">
        <div className="loading-spinner-lp"></div>
        <p>Loading...</p>
      </div>
    );
  }

  const features = [
    { icon: "🎁", title: "Customizable Programs", desc: "Create unique loyalty programs tailored to your business needs" },
    { icon: "💬", title: "Customer Engagement", desc: "Engage customers with email campaigns and push notifications" },
    { icon: "🛡️", title: "White Label Solution", desc: "Offer a seamless branded experience to your customers" }
  ];

  const benefits = [
    { icon: 'users', title: 'Customer satisfaction', desc: 'Increase customer retention and loyalty' },
    { icon: 'chart-line', title: 'Bottom line', desc: 'Boost sales and revenue' },
    { icon: 'gamepad', title: 'Understand your customer better', desc: 'Gain valuable customer insights' },
    { icon: 'plug', title: 'Control', desc: 'Enhance brand reputation' }
  ];

  return (
    <div className="landing-page full-width" id="lp1">
      <div className="landing-content">
        <svg className="landing-logo" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="45" fill="#4285F4" />
          <path d="M30 50 L50 70 L70 30" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        
        <h1 className="landing-title">
          <span className="text-blue">Boost customer loyalty</span>
          <br />
          <span className="text-dark">in minutes</span>
        </h1>
        
        <p className="landing-description">
          Empower your business with our easy-to-use loyalty program solution.
        </p>

        <a href="https://wa.me/27817501758" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
          <FontAwesomeIcon icon={['fab', 'whatsapp']} />
          <span className="whatsapp-text"> Chat with us</span>
        </a>

        <Link onClick={() => setShowEnquiryForm(true)} className="cta-button">
          Enquire Now
        </Link>
        
        <p className="landing-footnote">
          Start rewarding your customers today.
        </p>

        <section className="featured-vendors"> 
        <h2>Featured Vendors</h2>    
            <div className="vendors-grid">
          {featuredVendors.map(vendor => (
            <Link 
              key={vendor.id || 'cb'} // Fallback ID
              to={`/vendor/${vendor.sub_url || 'cb'}`} // Fallback URL
              className="vendor-card"
            >
              <img 
                src={vendor.logo_url || '/default-logo.png'}
                alt={vendor.business_name}
                className="vendor-logo-lp"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/default-logo.png';
                }}
              />
              <h3>{vendor.business_name}</h3>
              <p>{vendor.address}</p>
              <span className="vendor-app-btn">Vendor App</span>
            </Link>
          ))}
        </div>
      </section>
      
      </div>

     

      <section className="features-section">
        <h2>Powerful Features</h2>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-card">
              <span className="feature-icon">{feature.icon}</span>
              <h3>{feature.title}</h3>
              <p>{feature.desc}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="brand-image-section">
        <svg className="brand-image" width="200" height="100" viewBox="0 0 200 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 80 C20 80 50 20 100 50 C150 80 180 20 180 20" stroke="#4285F4" strokeWidth="4" strokeLinecap="round"/>
          <circle cx="20" cy="80" r="8" fill="#4285F4"/>
          <circle cx="100" cy="50" r="8" fill="#4285F4"/>
          <circle cx="180" cy="20" r="8" fill="#4285F4"/>
        </svg>
      </section>

      <section className="benefits-section">
        <h2>Benefits for Your Business</h2>
        <p>Loyalty and more...</p>
        <div className="benefits-grid">
          {benefits.map((benefit, index) => (
            <div key={index} className="benefit-item">
              <div className="benefit-icon">
                <FontAwesomeIcon icon={benefit.icon} />
              </div>
              <h3>{benefit.title}</h3>
              <p>{benefit.desc}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="cta-section">
        <h2>Ready to Grow Your Business?</h2>
        <div className="cta-buttons">
          <button onClick={() => setShowEnquiryForm(true)} className="cta-button cta-button-secondary">
            Request More Info
          </button>
        </div>
      </section>

      <footer className="landing-footer">
        <p>&copy; 2025 My Local. All rights reserved.</p>
      </footer>

      {showEnquiryForm && <EnquiryForm onClose={() => setShowEnquiryForm(false)} />}
    </div>
  );
};

export default LandingPageWithErrorBoundary;