import React, { useEffect, useState } from 'react';
import '../styles/NotificationPopup.css';

const NotificationPopup = ({ onAllow, onClose, customMessage, status }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (status?.error || status?.message) {
      const statusTimer = setTimeout(() => {
        handleClose(); // Close the popup after 2 seconds
      }, 2000);

      return () => clearTimeout(statusTimer);
    }
  }, [status]);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  if (!isVisible) return null;

  return (
    <div className="notification-popup-overlay">
      <div className="notification-popup">
        <button className="close-button" onClick={handleClose}>&times;</button>
        {status?.error && <p className="error-message">{status.error}</p>}
        {status?.message && <p className="success-message">{status.message}</p>}
        <p>{customMessage || "We would like to send you updates to stay in the know"}</p>
        {/* Only show buttons if there is no status */}
        {!status?.error && !status?.message && (
          <div className="notification-buttons">
            <button onClick={onAllow} className="allow-btn">Allow</button>
            <button onClick={handleClose} className="block-btn">Not Now</button>
          </div>
        )}
      </div>
    </div>
  );
  
};

export default NotificationPopup;
