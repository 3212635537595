import React, { useState, useEffect, useCallback, useRef, createContext } from 'react';
import debounce from 'lodash.debounce';
import { useParams, useNavigate, useSearchParams, Link } from 'react-router-dom';
import axios from '../utils/axiosConfig';
import { setAuthToken, isTokenValid, logout } from '../utils/auth';
import '../styles/VendorPublicPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BookingSection from './BookingSection';
import LoginModal from './LoginModal';
import Inbox from './Inbox';
import CustomerProfileEdit from './CustomerProfileEdit';
import PasswordReset from './PasswordReset';
import BookingModal from './BookingModal';
import PinRedemptionModal from './PinRedemptionModal';
import { IOSPWAHandler } from '../utils/iosPwaHandler';
import { checkPushNotificationSupport } from '../utils/notificationUtils';
import PushNotificationSubscription from './PushNotificationSubscription';

export const VendorContext = createContext(null);

const carouselResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};

function VendorPublicPage() {
  const [activeTab, setActiveTab] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [enrolledPrograms, setEnrolledPrograms] = useState([]);
  const [standaloneVouchers, setStandaloneVouchers] = useState([]);
  const { subUrl } = useParams();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const [slides, setSlides] = useState([]);
  const [specials, setSpecials] = useState([]);
  const [menus, setMenus] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const profileMenuRef = useRef(null);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [enrollingProgramId, setEnrollingProgramId] = useState(null);
  const [showInbox, setShowInbox] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [customerProfile, setCustomerProfile] = useState(null);
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [searchParams] = useSearchParams();
  const fromPasswordReset = searchParams.get('fromReset') === 'true';
  const [activeSubTab, setActiveSubTab] = useState('specials');

  const [eventSections, setEventSections] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [bookingContext, setBookingContext] = useState(null);
  const [showPinModal, setShowPinModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [redemptionType, setRedemptionType] = useState(null);
  const [vendorSettings, setVendorSettings] = useState(null);
  const [isLoadingVendorSettings, setIsLoadingVendorSettings] = useState(false);

  const [iosPwaHandler, setIosPwaHandler] = useState(null);
  const [showIOSInstall, setShowIOSInstall] = useState(false);
  const [shouldShowPushPrompt, setShouldShowPushPrompt] = useState(false);


  const fetchCustomerProfile = useCallback(async () => {
    if (isLoggedIn) {
      try {
        const response = await axios.get('/api/customers/profile');
        setCustomerProfile(response.data);
      } catch (error) {
        console.error('Error fetching customer profile:', error);
      }
    }
  }, [isLoggedIn]);

  const fetchNotifications = useCallback(async () => {
    if (vendor && isLoggedIn) {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          setAuthToken(token);
        }
        const response = await axios.get(`/api/vendors/${vendor.id}/notifications`);
        setNotifications(response.data);
        setUnreadCount(response.data.filter(n => !n.is_read).length);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }
  }, [vendor, isLoggedIn]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    fetchCustomerProfile();
  }, [fetchCustomerProfile, isLoggedIn]);

  useEffect(() => {
    // Auto-open login modal if coming from password reset
    if (fromPasswordReset) {
      setShowLoginModal(true);
    }
  }, [fromPasswordReset]);


  const fetchEventSections = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/event-sections`);
      setEventSections(response.data);
    } catch (error) {
      console.error('Error fetching event sections:', error);
      // If the vendor isn't events-enabled, this will just set empty data
      setEventSections([]);
    }
  }, [subUrl]);
  
  const fetchLiveEvents = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/live-events`);
      setLiveEvents(response.data);
    } catch (error) {
      console.error('Error fetching live events:', error);
      setLiveEvents([]);
    }
  }, [subUrl]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      await axios.put(`/api/vendors/notifications/${notificationId}/read`);
      setNotifications(prevNotifications => 
        prevNotifications.map(notif => 
          notif.id === notificationId ? { ...notif, is_read: true } : notif
        )
      );
      setUnreadCount(prev => prev - 1);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleAboutClick = useCallback((program) => {
    setSelectedProgram(program);
    setShowAboutModal(true);
  }, []);
  
  useEffect(() => {
    const handleDeepLink = () => {
      const params = new URLSearchParams(window.location.search);
      const tab = params.get('tab');
  
      if (tab) {
        setActiveTab(tab);
        // Remove the tab parameter from the URL after handling
        const newUrl = new URL(window.location);
        newUrl.searchParams.delete('tab');
        window.history.replaceState({}, '', newUrl);
      }
    };
  
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'NOTIFICATION_CLICK') {
       const { tab, vendorSubUrl } = event.data;
       console.log('Notification clicked, data:', event.data);
       if (tab === 'inbox') {
         if (isLoggedIn) {
           console.log('User logged in, navigating to inbox');
           setActiveTab('loyalty');
           setShowInbox(true);
         } else {
           console.log('User not logged in, showing login modal');
           setShowLoginModal(true);
         }
       } else {
         console.log(`Navigating to tab: ${tab}`);
         setActiveTab(tab);
       }
        console.log('Notification clicked, vendorSubUrl:', vendorSubUrl);
           // You can add more logic here if needed, e.g., navigate to a specific page																	 
     }
   };
  
    handleDeepLink();
  
    // Add event listener for 'message' events from the service worker
    navigator.serviceWorker.addEventListener('message', handleMessage);
  
    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
    };
  }, []);
  
  // Keep the about modal logic separate
  useEffect(() => {
    const handleAboutModalUrl = () => {
      const currentUrl = new URL(window.location);
      if (showAboutModal && selectedProgram) {
        currentUrl.searchParams.set('programId', selectedProgram.id);
      } else {
        currentUrl.searchParams.delete('programId');
      }
      window.history.replaceState({}, '', currentUrl);
    };
  
    handleAboutModalUrl();
  }, [showAboutModal, selectedProgram]);



  const updateManifest = useCallback(
    debounce(() => {
      try {
        const link = document.querySelector("link[rel='manifest']");
        const newHref = `${process.env.REACT_APP_API_URL}/manifest.json?subUrl=${subUrl}`;
        console.log('Updating manifest to:', newHref);
        
        if (link) {
          if (link.href !== newHref) {
            link.href = newHref;
          }
        } else {
          const newLink = document.createElement('link');
          newLink.rel = 'manifest';
          newLink.href = newHref;
          document.head.appendChild(newLink);
        }
      } catch (error) {
        console.error('Error updating manifest:', error);
      }
    }, 500),
    [subUrl]
  );
  
 
  const fetchVendorInfo = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}`);
      setVendor(response.data);
    } catch (error) {
      console.error('Error fetching vendor info:', error);
    }
  }, [subUrl, setVendor]);
  
  const fetchSpecials = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/specials`);
      setSpecials(response.data.specials);
    } catch (error) {
      console.error('Error fetching specials:', error);
    }
  }, [subUrl]);
  
  const fetchMenus = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/menus`);
      setMenus(response.data);
    } catch (error) {
      console.error('Error fetching menus:', error);
    }
  }, [subUrl]);
  
  const fetchPrograms = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/programs`);
      setPrograms(response.data);
    } catch (error) {
      console.error('Error fetching programs:', error);
      setError('Failed to fetch programs. Please try again later.');
    }
  }, [subUrl]);
  
  const fetchEnrolledPrograms = useCallback(async () => {
    if (!isTokenValid()) {
      setEnrolledPrograms([]);
      setStandaloneVouchers([]);
      return;
    }
    try {
      const response = await axios.get('/api/customers/programs');
      console.log('Fetched enrolled programs:', response.data);
      
      const filteredPrograms = response.data.enrolledPrograms.filter(p => p.vendor_sub_url === subUrl);
      setEnrolledPrograms(filteredPrograms || []);
  
      const filteredVouchers = response.data.standaloneVouchers.filter(v => v.vendor_sub_url === subUrl);
      setStandaloneVouchers(filteredVouchers || []);
    } catch (error) {
      console.error('Error fetching enrolled programs:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
      }
      setEnrolledPrograms([]);
      setStandaloneVouchers([]);
    }
  }, [subUrl]);
  
  const fetchSlides = useCallback(async () => {
    try {
      const response = await axios.get(`/api/vendors/${subUrl}/public-slides`);
      setSlides(response.data);
    } catch (error) {
      console.error('Error fetching slides:', error);
      setError('Failed to fetch slides. Please try again later.');
    }
  }, [subUrl]);
  
  const debouncedFetchData = useCallback(
    debounce(async () => {
      try {
        setIsLoading(true);
        await Promise.all([
          fetchVendorInfo(),
          fetchPrograms(),
          fetchEnrolledPrograms(),
          fetchSlides(),
          fetchSpecials(),
          fetchMenus(),
          fetchEventSections(),
          fetchLiveEvents() 
        ]);
        setError(null);
      } catch (err) {
        setError('Failed to load vendor data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [fetchVendorInfo, fetchPrograms, fetchEnrolledPrograms, fetchSlides, fetchSpecials, fetchMenus,fetchEventSections, fetchLiveEvents]
  );
  
// Main initialization effect
useEffect(() => {
  const token = localStorage.getItem('token');
  if (token && isTokenValid()) {
    setAuthToken(token);
    setIsLoggedIn(true);
  } else {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  }
  
  const checkStandalone = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsInstallable(!isStandalone);
  };

  checkStandalone();
  debouncedFetchData();

  const handleClickOutside = (event) => {
    if (profileMenuRef.current && !profileMenuRef.current.contains(event.target) && !event.target.closest('.profile-button')) {
      setShowProfileMenu(false);
    }
  };

  const handleAppInstalled = () => {
    console.log('App installed');
    setIsInstallable(false);
    setDeferredPrompt(null);
  };

  document.addEventListener('mousedown', handleClickOutside);
  window.addEventListener('appinstalled', handleAppInstalled);

  // Check for display-mode changes
  const mediaQuery = window.matchMedia('(display-mode: standalone)');
  mediaQuery.addListener(checkStandalone);

  console.log('Initial isInstallable:', isInstallable);
  console.log('Initial deferredPrompt:', deferredPrompt);

  return () => {
    debouncedFetchData.cancel();
    fetchVendorSettings.cancel();
    document.removeEventListener('mousedown', handleClickOutside);
    window.removeEventListener('appinstalled', handleAppInstalled);
    mediaQuery.removeListener(checkStandalone);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
  // Check if logged in and set appropriate tab
  if (isLoggedIn) {
    setActiveTab('loyalty');
  } else if (!isLoggedIn && slides.length === 0) {
    // If not logged in and no slides, show loyalty tab
    setActiveTab('loyalty');
  } else if (!isLoggedIn && slides.length > 0) {
    // If not logged in and has slides, keep activeTab as null to show welcome screen
    setActiveTab(null);
  }
}, [isLoggedIn, slides]); // Dependencies include isLoggedIn and slides

// Separate effect for install prompt handling
useEffect(() => {
  const handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    if (!iosPwaHandler?.isIOS) {  // This means "if NOT iOS"
      setDeferredPrompt(e);       // Set the prompt for non-iOS devices
      setIsInstallable(true);
    }
  };

  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

  return () => {
    window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  };
}, [iosPwaHandler]); // Now we can safely include iosPwaHandler as a dependency

  
  const handleProfileButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowProfileMenu(!showProfileMenu);
  };
  
  const handleMenuItemClick = (action) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowProfileMenu(false);
    setShowProfileEdit(false);
    action();
  };
  
  const handleLogout = () => {
    logout();
    localStorage.removeItem('token');
    setAuthToken(null);
    setIsLoggedIn(false);
    setShowProfileMenu(false);
    window.location.reload();
  };
  
  const handleInstallClick = useCallback(() => {
    if (iosPwaHandler?.isIOS) {
      console.log('iOS device detected, showing installation instructions');
      iosPwaHandler.updateManifest(); // Force manifest update
      setShowIOSInstall(true);
      return;
    }
  
    if (deferredPrompt) {
      console.log('Showing native install prompt');
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        console.log('User installation choice:', choiceResult.outcome);
        if (choiceResult.outcome === 'accepted') {
          setIsInstallable(false);
        }
        setDeferredPrompt(null);
      });
    } else {
      console.log('No installation prompt available');
    }
  }, [deferredPrompt, iosPwaHandler]);


  const handleBookEvent = (type, data) => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
      sessionStorage.setItem('pendingBooking', JSON.stringify({ type, ...data }));
      return;
    }
    setBookingContext({ type, ...data });
    setShowBookingModal(true);
  };

  const fetchVendorSettings = useCallback(
    debounce(async () => {
      if (!vendor?.id) {
        console.log('No vendor ID available');
        return;
      }
      
      try {
        setIsLoadingVendorSettings(true);
        console.log('Fetching settings for vendor:', vendor.id);
        const response = await axios.get(`/api/vendors/${vendor.id}/redemption-settings`);
        console.log('Received vendor settings:', response.data);
        setVendorSettings(response.data);
      } catch (error) {
        console.error('Error fetching vendor settings:', error);
      } finally {
        setIsLoadingVendorSettings(false);
      }
    }, 300),
    [vendor?.id]
  );

  useEffect(() => {
    if (vendor?.id) {
      fetchVendorSettings();
    }
  }, [vendor, fetchVendorSettings]);


  const handleRedeemVoucher = async (voucherId, pin, type = 'program') => {
    try {
      setIsRedeeming(true);
      await axios.post('/api/customers/redeem-voucher', { 
        voucherId, 
        pin,
        programId: type === 'program' ? selectedVoucher.programId : null,
        isStandalone: type === 'standalone'
      });
      
      // Refresh the data
      await fetchEnrolledPrograms();
      setShowPinModal(false);
      setSelectedVoucher(null);
      setRedemptionType(null);
      alert('Voucher redeemed successfully');
    } catch (error) {
      console.error('Error redeeming voucher:', error);
      alert(error.response?.data?.error || 'Error redeeming voucher');
    } finally {
      setIsRedeeming(false);
    }
  };
  
  // Add this function to handle redemption button click
  const handleRedeemClick = (voucher, type) => {
    setSelectedVoucher(voucher);
    setRedemptionType(type);
    setShowPinModal(true);
  };

// Primary iOS PWA initialization effect
useEffect(() => {
  if (vendor) {
    const pwaHandler = new IOSPWAHandler(vendor, subUrl);
    
    if (pwaHandler.isIOS) {
      setIosPwaHandler(pwaHandler);
      pwaHandler.addIOSMetaTags();
      pwaHandler.updateManifest();
    }
  }
}, [vendor, subUrl]);

// Remove or modify other manifest update calls
useEffect(() => {
  if (!iosPwaHandler?.isIOS) {
    updateManifest();
  }
}, [subUrl, iosPwaHandler, updateManifest]);

// Update visibility and installation handling
useEffect(() => {
  if (!iosPwaHandler?.isIOS) return;

  // Debounced manifest update handler
  const debouncedManifestUpdate = debounce(() => {
    if (!document.hidden && iosPwaHandler) {
      console.log('Updating manifest after visibility change');
      iosPwaHandler.updateManifest();
    }
  }, 1000);

  // Visibility change handler
  const handleVisibilityChange = () => {
    if (!document.hidden) {
      debouncedManifestUpdate();
    }
  };

  // Listen for page visibility changes
  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Clean up
  return () => {
    debouncedManifestUpdate.cancel();
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [iosPwaHandler]);


useEffect(() => {
  if (isLoggedIn && !showLoginModal) {
    // Check if we should show push notification prompt
    const support = checkPushNotificationSupport(iosPwaHandler);
    if (support.isSupported) {
      const lastPrompt = localStorage.getItem('lastPushPrompt');
      const now = Date.now();
      
      // Show prompt if:
      // 1. Never shown before, or
      // 2. Last shown more than 7 days ago
      if (!lastPrompt || (now - parseInt(lastPrompt) > 7 * 24 * 60 * 60 * 1000)) {
        setShouldShowPushPrompt(true);
        localStorage.setItem('lastPushPrompt', now.toString());
      }
    }
  }
}, [isLoggedIn, showLoginModal, iosPwaHandler]);


  const IOSInstallPrompt = ({ instructions, onClose }) => {
    if (!instructions) return null;
  
    return (
      <div className="ios-install-prompt">
        <div className="ios-install-content">
          <button className="close-button" onClick={onClose}>&times;</button>
          <h3>{instructions.title}</h3>
          <ol className="install-steps">
            {instructions.steps.map((step, index) => (
              <li key={index}>{step}</li>
            ))}
          </ol>
          {instructions.image && (
            <img 
              src={instructions.image} 
              alt="Installation guide" 
              className="install-guide-image"
            />
          )}
          <button className="understand-button" onClick={onClose}>
            Got it
          </button>
        </div>
      </div>
    );
  };
  
  const handleShareClick = () => {
    const currentUrl = window.location.href;
    if (navigator.share) {
      navigator.share({
        title: vendor?.business_name,
        text: `Check out the ${vendor?.business_name} Loyalty App!`,
        url: currentUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
    } else {
      // Fallback for browsers that don't support the Web Share API
      navigator.clipboard.writeText(currentUrl)
        .then(() => {
          alert('Link copied to clipboard!');
        })
        .catch((error) => {
          console.error('Failed to copy link:', error);
        });
    }
  };
  
  // const promptPWA = () => {
  //   if (deferredPrompt) {
  //     deferredPrompt.prompt();
  //     deferredPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the install prompt');
  //       } else {
  //         console.log('User dismissed the install prompt');
  //       }
  //       setDeferredPrompt(null);
  //     });
  //   }
  // };


  const renderWelcomeScreen = () => (
    <div className="welcome-screen">
      <h1>Welcome</h1>
      {slides.length > 0 ? (
        <Carousel
          responsive={carouselResponsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          showDots={true}
          arrows={true}
          className="vendor-carousel"
          containerClass="carousel-container"
          itemClass="carousel-item"
        >
          {Array.isArray(slides) && slides.map((slide, index) => (
            <img 
              key={index} 
              src={slide.image_url} 
              alt={`Slide ${index + 1}`} 
              className="carousel-image" 
            />
          ))}
        </Carousel>
      ) : (
        <img src={vendor?.cover_url} alt="Cover" className="cover-image" />
      )}
      <div className="welcome-screen-buttons">
          {isInstallable && !window.matchMedia('(display-mode: standalone)').matches && (
            <button className="install-app-btn" onClick={handleInstallClick}>Install App</button>
          )}
           {!isLoggedIn && (
            <>
              <button className="login-btn" onClick={() => setShowLoginModal(true)}>Login</button>
              <button className="register-btn" onClick={() => navigate(`/register/${subUrl}`)}>Register</button>
            </>
          )}
        </div>
    </div>
  );

  const renderProfileMenu = () => (
    <div ref={profileMenuRef} className={`profile-menu ${showProfileMenu ? 'active' : 'hidden'}`}>
      {isLoggedIn && customerProfile && (
        <>
          <div className="customer-info">
            <p>{customerProfile.full_name}</p>
            <p>Email: {customerProfile.email}</p>
            <p>Loyalty Number: {customerProfile.loyalty_number}</p>
          </div>
          <div className="menu-actions">
            <button onClick={handleMenuItemClick(() => setShowProfileEdit(true))}>
              Edit Profile
            </button>
            <button onClick={handleMenuItemClick(() => setShowInbox(true))}>
              Inbox {unreadCount > 0 && `(${unreadCount})`}
            </button>
          </div>
        </>
      )}
      <button onClick={handleMenuItemClick(handleShareClick)}>
      <FontAwesomeIcon icon="share" /> Share Page
    </button>
    <button 
                  onClick={() => navigate(`/vendor/${subUrl}/review`)}
                  className="review-button-review"
                >
                  Rate Experience
      </button>
      {isLoggedIn ? (
        <button onClick={handleMenuItemClick(handleLogout)}>Logout</button>
      ) : (
        <>
          <button onClick={handleMenuItemClick(() => setShowLoginModal(true))}>Login</button>
          <button onClick={handleMenuItemClick(() => navigate(`/register/${subUrl}`))}>Register</button>
        </>
      )}
    </div>
  );



  const enrollInProgram = useCallback(async (programId) => {
    const token = localStorage.getItem('token');
    if (!token || !isTokenValid()) {
      setEnrollingProgramId(programId);
      setShowLoginModal(true);
      return;
    }
    try {
      const response = await axios.post('/api/customers/enroll', { programId });
      console.log('Enrollment response:', response.data);
      setEnrolledPrograms(prev => [...prev, response.data.enrolledProgram]);
      alert('Successfully enrolled in the program!');
    } catch (error) {
      console.error('Error enrolling in program:', error);
      if (error.response && error.response.status === 401) {
        alert('Your session has expired. Please log in again.');
        navigate('/login');
      } else if (error.response && error.response.status === 400) {
        alert(error.response.data.error || 'You are already enrolled in this program.');
      } else {
        alert('Failed to enroll in the program. Please try again.');
      }
    }
  }, [navigate]);

  const handleLoginSuccess = (user) => {
    setIsLoggedIn(true);
    setShowLoginModal(false);
    
    // Remove fromReset query parameter
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('fromReset');
    window.history.replaceState({}, '', currentUrl);
    
    if (enrollingProgramId) {
      enrollInProgram(enrollingProgramId);
      setEnrollingProgramId(null);
    }
    fetchEnrolledPrograms();
    fetchPrograms();
  
    const params = new URLSearchParams(window.location.search);
    if (params.get('tab') === 'inbox') {
      console.log('Inbox tab requested after login');
      setActiveTab('loyalty');
      setShowInbox(true);
      params.delete('tab');
      window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
      console.log('Inbox tab opened, URL parameters cleared');
    }
  };

  const renderSpecialsSubNav = () => {
    if (!vendor.events_enabled || activeTab !== 'specials') return null;
  
    return (
      <div className="specials-subnav">
        <button
          onClick={() => setActiveSubTab('specials')}
          className={activeSubTab === 'specials' ? 'active' : ''}
        >
          Specials
        </button>
        <button
          onClick={() => setActiveSubTab('book-event')}
          className={activeSubTab === 'book-event' ? 'active' : ''}
        >
          Event Menus
        </button>
        <button
          onClick={() => setActiveSubTab('live-music')}
          className={activeSubTab === 'live-music' ? 'active' : ''}
        >
          Upcoming Events
        </button>
      </div>
    );
  };

  const renderVoucherWithRedemption = (voucher, type) => {
    if (voucher.redeemed_at) {
      console.log('Voucher is already redeemed');
      return null;
    }
    
    if (isLoadingVendorSettings) {
      console.log('Loading vendor settings...');
      return <div>Loading...</div>;
    }
    
    console.log('Vendor Settings:', vendorSettings);
    console.log('Customer Redemption Enabled:', vendorSettings?.customerRedemptionEnabled);
    
    // Only show redemption button if enabled in vendor settings and user is logged in
    if (!vendorSettings?.customerRedemptionEnabled || !isLoggedIn) {
      console.log('Customer redemption is not enabled or user not logged in');
      return null;
    }
  
    return (
      <button 
        onClick={() => handleRedeemClick(voucher, type)}
        className="redeem-voucher-btn"
      >
        Redeem Voucher
      </button>
    );
  };



  const renderTabContent = () => {
    if (showProfileEdit) {
      return (
        <CustomerProfileEdit 
          customerProfile={customerProfile}
          onClose={() => setShowProfileEdit(false)}
          onUpdate={() => {
            fetchCustomerProfile();
            setShowProfileEdit(false);
          }}
        />
      );
    }
  
  
    if (showInbox) {
      return (
        <VendorContext.Provider value={{ notifications, handleMarkAsRead, fetchNotifications, vendorId: vendor?.id }}>
          <Inbox onClose={() => setShowInbox(false)} />
        </VendorContext.Provider>
      );
    }

    if (!activeTab && !isLoggedIn && slides.length > 0) {
      return renderWelcomeScreen();
    }
  

    switch (activeTab) {
      case 'loyalty':
        return (
          <div className="loyalty-tab">

        <div className="action-buttons">
            {isInstallable && !window.matchMedia('(display-mode: standalone)').matches && (
              <button className="install-app-btn" onClick={handleInstallClick}>Install App</button>
            )}
      
            {!isLoggedIn && (
              <>
                <button className="login-btn" onClick={() => setShowLoginModal(true)}>Login</button>
                <button className="register-btn" onClick={() => navigate(`/register/${subUrl}`)}>Register</button>
              </>
            )}

          </div>

             <h2>Your loyalty rewarded</h2>

              {Array.isArray(enrolledPrograms) && enrolledPrograms.filter(program => program.active).length > 0 ? (
                enrolledPrograms
                  .filter(program => program.active)
                  .map(program => (
                    <div key={program.id} className="program-card enrolled">
                      <h3>{program.name}</h3>
                      <p className="voucher-description">Full card reward: {program.description}</p>
                      <p>Stamps required: {program.stamps_required}</p>
                      <Link to={`/loyalty-card/${program.id}`} className="view-card-link">
                        View Loyalty Card
                      </Link>
                    </div>
                  ))
              ) : (
                <p className="no-enrolled-programs">You are not currently enrolled in any loyalty programs.</p>
              )}
      
            {Array.isArray(programs) && programs.filter(program => 
              program.active && 
              !enrolledPrograms.some(ep => ep.id === program.id)
            ).length > 0 && (
              <>
                <h2>New Programs</h2>
                {programs
                  .filter(program => 
                    program.active && 
                    !enrolledPrograms.some(ep => ep.id === program.id)
                  )
                  .map(program => (
                    <div key={program.id} className="program-card">
                      <h3>{program.name}</h3>
                      <p>Stamps required: {program.stamps_required}</p>
                      <button className="enroll-btn" onClick={() => enrollInProgram(program.id)}>
                        Enroll
                      </button>
                      <button className="about-btn" onClick={() => handleAboutClick(program)}>
                        About
                      </button>
                    </div>
                  ))
                }
              </>
            )}

        
            <div className="vouchers-section">
              {(enrolledPrograms.some(p => p.vouchers?.length > 0) || standaloneVouchers.length > 0) && (
                <h3>All Vouchers</h3>
              )}
              
              {enrolledPrograms.some(p => p.vouchers?.length > 0) && (
                <>
                  <h4>Program Vouchers</h4>
                  {enrolledPrograms.flatMap(program => 
                    (program.vouchers || [])
                      .sort((a, b) => {
                        const aRedeemed = !!a.redeemed_at;
                        const bRedeemed = !!b.redeemed_at;
                        return aRedeemed === bRedeemed ? 0 : aRedeemed ? 1 : -1;
                      })
                      .map(voucher => (
                        <div key={`program-${program.id}-${voucher.id}`} className={`voucher ${voucher.redeemed_at ? 'redeemed' : 'unredeemed'}`}>
                          <div className="voucher-content">
                            <p>{program.name}: {program.description || 'No description'}</p>
                            <p>Status: {voucher.redeemed_at ? 'Redeemed' : 'Available'}</p>
                            <p>Created: {new Date(voucher.created_at).toLocaleDateString()}</p>
                            {voucher.redeemed_at && 
                              <p>Redeemed: {new Date(voucher.redeemed_at).toLocaleDateString()}</p>
                            }
                            {renderVoucherWithRedemption({ ...voucher, programId: program.id }, 'program')}
                          </div>
                        </div>
                      ))
                  )}
                </>
              )}

              {standaloneVouchers.length > 0 && (
                <>
                  <h4>Promo Vouchers</h4>
                  {standaloneVouchers
                    .sort((a, b) => {
                      const aRedeemed = !!a.redeemed_at;
                      const bRedeemed = !!b.redeemed_at;
                      return aRedeemed === bRedeemed ? 0 : aRedeemed ? 1 : -1;
                    })
                    .map(voucher => (
                      <div key={`standalone-${voucher.id}`} className={`voucher ${voucher.redeemed_at ? 'redeemed' : 'unredeemed'}`}>
                        <div className="voucher-content">
                          <p>{voucher.description || `Value: R${voucher.value}`}</p>
                          <p>Status: {voucher.redeemed_at ? 'Redeemed' : 'Available'}</p>
                          <p>Created: {new Date(voucher.created_at).toLocaleDateString()}</p>
                          {voucher.redeemed_at && 
                            <p>Redeemed: {new Date(voucher.redeemed_at).toLocaleDateString()}</p>
                          }
                          {renderVoucherWithRedemption(voucher, 'standalone')}
                        </div>
                      </div>
                    ))
                  }
                </>
              )}
            </div>


          </div>
        );
        case 'menu':
        return (
          <div className="menu-tab">
            <h2>Our Menus</h2>
            {menus.map(menu => (
              <div key={menu.id} className="menu-item">
                {menu.thumbnail_url && (
                  <img src={menu.thumbnail_url} alt="Menu thumbnail" className="menu-thumbnail" />
                )}
                {menu.pdf_url && (
                  <a href={menu.pdf_url} target="_blank" rel="noopener noreferrer" className="view-menu-btn">
                    View PDF Menu
                  </a>
                )}
                {menu.weblink && (
                  <a href={menu.weblink} target="_blank" rel="noopener noreferrer" className="view-menu-btn">
                    View Online Menu
                  </a>
                )}
              </div>
            ))}
          </div>
        );
              case 'specials':
        if (!vendor.events_enabled) {
          return (
            <div className="specials-tab">
              <h2>Our Specials</h2>
              {specials.map(special => (
                <div key={special.id} className="special-item">
                  <h3>{special.name}</h3>
                  <p>{special.description}</p>
                  {special.value && <p>Value: R{special.value}</p>}
                  {special.image_url && (
                    <div className="special-image-container">
                      <img src={special.image_url} alt={special.name} className="special-image" />
                    </div>
                  )}
                  {special.weblink && (
                    <a href={special.weblink} target="_blank" rel="noopener noreferrer" className="special-link">
                      Learn More
                    </a>
                  )}
                </div>
              ))}
            </div>
          );
        }

        return (
          <div className="specials-events-tab">
            {renderSpecialsSubNav()}
            
            {activeSubTab === 'specials' && (
              <div className="specials-content">
                <h2>Our Specials</h2>
                {specials.map(special => (
                  <div key={special.id} className="special-item">
                    <h3>{special.name}</h3>
                    <p>{special.description}</p>
                    {special.value && <p>Value: R{special.value}</p>}
                    {special.image_url && (
                      <div className="special-image-container">
                        <img src={special.image_url} alt={special.name} className="special-image" />
                      </div>
                    )}
                    {special.weblink && (
                      <a href={special.weblink} target="_blank" rel="noopener noreferrer" className="special-link">
                        Learn More
                      </a>
                    )}
                  </div>
                ))}
              </div>
            )}

            {activeSubTab === 'book-event' && (
              <div className="book-event-content">
                <h2>Book Your Event</h2>
                {eventSections.map(section => (
                  <div key={section.id} className="event-section">
                    <h3>{section.title}</h3>
                    {section.description && <p className="section-description">{section.description}</p>}
                    
                    <div className="event-options">
                      {section.options?.map(option => (
                        <div key={option.id} className="event-option">
                          <h4>{option.subtitle}</h4>
                          {option.file_url && (
                            <div className="option-file">
                              {option.file_type === 'image' ? (
                                <img src={option.file_url} alt={option.subtitle} className="option-image" />
                              ) : (
                                <div className="pdf-preview">
                                  {option.thumbnail_url ? (
                                    <div className="pdf-thumbnail-container">
                                      <img 
                                        src={option.thumbnail_url} 
                                        alt={`${option.subtitle} preview`} 
                                        className="pdf-thumbnail" 
                                      />
                                      <a 
                                        href={option.file_url} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="view-pdf-btn"
                                      >
                                        View Menu/Details
                                      </a>
                                    </div>
                                  ) : (
                                    <a 
                                      href={option.file_url} 
                                      target="_blank" 
                                      rel="noopener noreferrer"
                                      className="pdf-link"
                                    >
                                      <div className="pdf-icon">PDF</div>
                                      <span>View Menu/Details</span>
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          <button 
                            onClick={() => handleBookEvent('event_menu', {
                              title: section.title,
                              option: option.subtitle,
                              sectionId: section.id,
                              optionId: option.id
                            })}
                            className="book-option-btn"
                          >
                            Book This Option
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {activeSubTab === 'live-music' && (
              <div className="live-music-content">
                <h2>Upcoming Events</h2>
                {liveEvents.length > 0 ? (
                  liveEvents.map(event => (
                    <div key={event.id} className="live-event">
                      <h3>{event.title}</h3>
                      <p className="event-date">
                        {new Date(event.event_date).toLocaleDateString()} at{' '}
                        {new Date(event.event_date).toLocaleTimeString()}
                      </p>
                      {event.description && <p className="event-description">{event.description}</p>}
                      {event.file_url && (
                        <div className="event-file">
                          {event.file_type === 'image' ? (
                            <img src={event.file_url} alt={event.title} className="event-image" />
                          ) : (
                            <div className="pdf-preview">
                              {event.thumbnail_url ? (
                                <div className="pdf-thumbnail-container">
                                  <img 
                                    src={event.thumbnail_url} 
                                    alt={`${event.title} preview`} 
                                    className="pdf-thumbnail" 
                                  />
                                  <a 
                                    href={event.file_url} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="view-pdf-btn"
                                  >
                                    View Details
                                  </a>
                                </div>
                              ) : (
                                <a 
                                  href={event.file_url} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="pdf-link"
                                >
                                  <div className="pdf-icon">PDF</div>
                                  <span>View Details</span>
                                </a>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      <button 
                        onClick={() => handleBookEvent('live_music', {
                          title: event.title,
                          eventId: event.id,
                          date: event.event_date
                        })}
                        className="book-event-btn"
                      >
                        Book This Event
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="no-events">No upcoming events scheduled.</p>
                )}
              </div>
            )}
          </div>
        );
      case 'booking':
        return <BookingSection vendorId={vendor.id} />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!vendor) return null;

  return (
    <div className="vendor-public-page">
       <header>
  <div className="logo-title-container">
    <img src={vendor.logo_url} alt="Vendor Logo" className="vendor-logo" />
  </div>
  <div className="header-buttons">
    <button onClick={handleProfileButtonClick} className="profile-button">
      <FontAwesomeIcon icon="user" />
    </button>
  </div>
</header>

    {renderProfileMenu()}

      {vendor.cover_url && (
        <div className="cover-image-container">
          <img src={vendor.cover_url} alt="Cover" className="cover-image" />
        </div>
      )}

      <div className="vendor-info">
        <h1 className="vtitle"> {vendor?.business_name} </h1>
        <p><FontAwesomeIcon icon="map-marker-alt" /> {vendor.address}</p>
        <p><FontAwesomeIcon icon="phone" /> {vendor.phone}</p>
      </div>

      <nav className="tab-navigation">
          <button onClick={() => {
            setActiveTab('loyalty');
            setShowInbox(false);
            setShowProfileEdit(false); 
          }} className={activeTab === 'loyalty' ? 'active' : ''}>
            Rewards
          </button>

          {menus.length > 0 && (
            <button onClick={() => {
              setActiveTab('menu');
              setShowInbox(false);
              setShowProfileEdit(false); 
            }} className={activeTab === 'menu' ? 'active' : ''}>
              Menus
            </button>
          )}

          {(specials.length > 0 || vendor.events_enabled) && (
            <button 
              onClick={() => {
                setActiveTab('specials');
                setActiveSubTab('specials');
                setShowInbox(false);
                setShowProfileEdit(false);
              }} 
              className={activeTab === 'specials' ? 'active' : ''}
            >
              {vendor.events_enabled ? 'Specials & Events' : 'Specials'}
            </button>
          )}

          <button onClick={() => {
            setActiveTab('booking');
            setShowInbox(false);
            setShowProfileEdit(false); 
          }} className={activeTab === 'booking' ? 'active' : ''}>
            Booking
          </button>
        </nav>

      <div className="tab-content">
        {showInbox ? renderTabContent() : (!isLoggedIn && !activeTab ? renderWelcomeScreen() : renderTabContent())}
        
          {showBookingModal && (
      <BookingModal
        isOpen={showBookingModal}
        onClose={() => {
          setShowBookingModal(false);
          setBookingContext(null);
        }}
        context={bookingContext}
        vendorId={vendor.id}
        onLoginRequired={() => {
          setShowBookingModal(false);
          setShowLoginModal(true);
        }}
      />
    )}

      </div>

    {showLoginModal && (
      <LoginModal
        onClose={() => setShowLoginModal(false)}
        onLoginSuccess={handleLoginSuccess}
        vendorSubUrl={subUrl}
        onForgotPassword={() => {
          setShowLoginModal(false);
          setShowPasswordReset(true);
        }}
      />
    )}

{showPasswordReset && (
      <PasswordReset
        isModal={true}
        isLoggedOut={!isLoggedIn}
        onClose={() => {
          setShowPasswordReset(false);
          setShowLoginModal(true); // Return to login modal after closing
        }}
        onSuccess={() => {
          setShowPasswordReset(false);
          if (isLoggedIn) {
            fetchCustomerProfile();
          } else {
            setShowLoginModal(true); // Show login modal after reset request
          }
        }}
      />
    )}

    {showAboutModal && selectedProgram && (
      <div className="about-modal">
        <div className="about-modal-content">
          <h3>About {selectedProgram.name}</h3>
          <p>{selectedProgram.about || 'No additional information available.'}</p>
          <button onClick={() => setShowAboutModal(false)}>Close</button>
        </div>
      </div>
    )}

      {showPinModal && (
        <PinRedemptionModal
          isOpen={showPinModal}
          onClose={() => {
            setShowPinModal(false);
            setSelectedVoucher(null);
            setRedemptionType(null);
          }}
          onSubmit={(pin) => handleRedeemVoucher(selectedVoucher.id, pin, redemptionType)}
          isSubmitting={isRedeeming}
        />
      )}

      {showIOSInstall && iosPwaHandler && (
            <IOSInstallPrompt 
              instructions={iosPwaHandler.showInstallInstructions()}
              onClose={() => setShowIOSInstall(false)}
            />
          )}

      {shouldShowPushPrompt && (
        <PushNotificationSubscription 
          iosPwaHandler={iosPwaHandler}
          onClose={() => setShouldShowPushPrompt(false)}
        />
      )}


      {vendor.whatsapp_number && (
        <a 
          href={`https://wa.me/${vendor.whatsapp_number}`} 
          className="whatsapp-button"
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'whatsapp']} />
          <span className="whatsapp-text"> Chat with us</span>
        </a>
      )}

      
    </div>
  );
}

export default VendorPublicPage;