import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosConfig';
import { setAuthToken } from '../utils/auth';
import '../styles/PushPermission.css';
import { checkPushNotificationSupport } from '../utils/notificationUtils';
import NotificationPopup from './NotificationPopup';

function PushNotificationSubscription({ iosPwaHandler }) {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const notificationSupport = checkPushNotificationSupport(iosPwaHandler);

  useEffect(() => {
    if (!notificationSupport.isSupported) {
      if (iosPwaHandler?.isIOS && !window.navigator.standalone) {
        setError('Push notifications are only available when installed as an app on iOS.');
      } else {
        setError('Push notifications are not supported in this browser');
      }
      return;
    }

    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
      checkAndUpdateSubscription();
    }
  }, [notificationSupport.isSupported]);


  
  const checkAndUpdateSubscription = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      let subscription = await registration.pushManager.getSubscription();
  
      if (subscription) {
        setIsSubscribed(true);
        await sendSubscriptionToServer(subscription);
      } else {
        setIsSubscribed(false);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error checking subscription:', error);
      setError('Failed to check subscription status: ' + error.message);
    }
  };

  const requestAndSubscribe = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        await subscribeUser();
      } else {
        setError('Please allow notifications to receive updates. You can enable them in your browser settings.');
      }
    } catch (error) {
      console.error('Error requesting permission:', error);
      setError('Failed to request notification permission: ' + error.message);
    } finally {
      setShowPopup(false);
    }
  };

  const sendSubscriptionToServer = async (subscription) => {
    try {
      await axios.post('/api/push-subscriptions/subscribe', { subscription });
      setMessage('Subscription successfully sent to server');
    } catch (error) {
      console.error('Error sending subscription to server:', error);
      if (error.response?.status === 410) {
        setIsSubscribed(false);
        setError('Your subscription is no longer valid. Please resubscribe.');
        setShowPopup(true);
      } else {
        setError('Failed to send subscription to server: ' + error.message);
      }
    }
  };

  const subscribeUser = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY)
      });

      setIsSubscribed(true);
      await sendSubscriptionToServer(subscription);
      setMessage('Successfully subscribed to push notifications');
      setError(null);
    } catch (error) {
      console.error('Failed to subscribe the user:', error);
      if (error.name === 'NotAllowedError') {
        setError('Please enable notifications in your browser settings to receive updates');
      } else {
        setError('Failed to subscribe to push notifications: ' + error.message);
      }
    }
  };

  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  return (
    <div>
      <NotificationPopup
        isOpen={showPopup}
        onAllow={requestAndSubscribe}
        onClose={() => setShowPopup(false)}
        onBlock={() => setShowPopup(false)}
        status={{ error, message }}
        customMessage={
          iosPwaHandler?.isIOS && !window.navigator.standalone
            ? "Install the app to receive notifications"
            : "We would like to send you updates to stay in the know"
        }
      />
    </div>
  );
}

export default PushNotificationSubscription;