export const checkPushNotificationSupport = (iosPwaHandler) => {
    // Check if browser supports service workers and push
    const hasServiceWorkerSupport = 'serviceWorker' in navigator;
    const hasPushSupport = 'PushManager' in window;
  
    // Check if it's iOS and PWA is installed
    const isIOSPWA = iosPwaHandler?.isIOS && window.navigator.standalone;
  
    // Return support status
    return {
      isSupported: hasServiceWorkerSupport && hasPushSupport && (!iosPwaHandler?.isIOS || isIOSPWA),
      isIOSPWA,
      hasServiceWorkerSupport,
      hasPushSupport
    };
  };