import React, { useContext, useEffect } from 'react';
import { VendorContext } from './VendorPublicPage';
import InboxPushSubscription from './InboxPushSubscription';
import '../styles/Inbox.css';

function Inbox({ onClose }) {
  const { notifications, handleMarkAsRead, fetchNotifications, vendorId } = useContext(VendorContext);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications, vendorId]);

  // Create a Set of unique notification bodies
  const uniqueNotifications = Array.from(new Set(notifications.map(n => n.body)))
    .map(body => notifications.find(n => n.body === body));

  return (
    <div className="inbox">
      <h2>Inbox</h2>
      <button onClick={onClose} className="close-inbox">Close Inbox</button>
      <InboxPushSubscription />
      {uniqueNotifications.length === 0 ? (
        <p>No notifications</p>
      ) : (
        <ul className="notification-list">
          {uniqueNotifications.map(notification => (
            <li key={notification.id} className={`notification ${notification.is_read ? 'read' : 'unread'}`}>
              <h3>{notification.title}</h3>
              <p>{notification.body}</p>
              <span className="notification-date">{new Date(notification.created_at).toLocaleString()}</span>
              {!notification.is_read && (
                <button onClick={() => handleMarkAsRead(notification.id)} className="mark-read-btn">
                  Mark as Read
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Inbox;