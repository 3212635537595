import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../utils/axiosConfig';
import debounce from 'lodash.debounce';
import { setAuthToken } from '../../utils/auth';
import '../../styles/ReviewManagement.css';

const ReviewManagement = () => {
    const [activeSection, setActiveSection] = useState('configuration');
    const [config, setConfig] = useState({
        questions: [],
        notification_emails: []
    });
    const [reviews, setReviews] = useState([]);
    const [stats, setStats] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saveStatus, setSaveStatus] = useState({ show: false, message: '', type: '' });

    // Define fetchVendorData before useEffect
    // const fetchVendorData = async () => {
    //     try {
    //         const [configRes, reviewsRes, statsRes] = await Promise.all([
    //             axios.get('/api/reviews/vendor/config'),
    //             axios.get('/api/reviews/vendor/reviews'),   
    //             axios.get('/api/reviews/vendor/stats')      
    //         ]);
            
    //         setConfig(configRes.data);
    //         setReviews(reviewsRes.data?.reviews || []);
    //         setStats(statsRes.data || {
    //             total_reviews: 0,
    //             average_rating: 0,
    //             last_30_days: 0
    //         });
    //         setIsLoading(false);
    //     } catch (err) {
    //         console.error('Error fetching review data:', err?.response?.data || err.message);
    //         setError('Error loading review data');
    //         setIsLoading(false);
    //     }
    // };

    const fetchVendorData = async () => {
        try {
            const [configRes, reviewsRes, statsRes] = await Promise.all([
                axios.get('/api/reviews/vendor/config'),
                axios.get('/api/reviews/vendor/reviews'),   
                axios.get('/api/reviews/vendor/stats')      
            ]);
            
            // Process the stats data to ensure numeric values
            const processedStats = {
                ...statsRes.data,
                total_reviews: Number(statsRes.data.total_reviews || 0),
                average_rating: Number(statsRes.data.average_rating || 0),
                last_30_days: Number(statsRes.data.last_30_days || 0),
                questionStats: statsRes.data.questionStats?.map(stat => ({
                    ...stat,
                    average_rating: Number(stat.average_rating || 0),
                    response_count: Number(stat.response_count || 0),
                    rating_distribution: stat.rating_distribution ? 
                        Object.fromEntries(
                            Object.entries(stat.rating_distribution)
                                .map(([key, value]) => [key, Number(value || 0)])
                        ) : {}
                })) || []
            };
            
            setConfig(configRes.data);
            setReviews(reviewsRes.data?.reviews || []);
            setStats(processedStats);
            setIsLoading(false);
        } catch (err) {
            console.error('Error fetching review data:', err?.response?.data || err.message);
            setError('Error loading review data');
            setIsLoading(false);
        }
    };

    const debouncedFetchData = useCallback(
        debounce(fetchVendorData, 300),
        [] // Empty dependency array since fetchVendorData doesn't depend on any props or state
    );

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthToken(token);
        }
        debouncedFetchData();
        return () => debouncedFetchData.cancel();
    }, [debouncedFetchData]);



    // const addQuestion = () => {
    //     setConfig(prev => ({
    //         ...prev,
    //         questions: [
    //             ...prev.questions,
    //             {
    //                 id: Date.now(),
    //                 type: 'star_rating',
    //                 text: '',
    //                 required: true
    //             }
    //         ]
    //     }));
    // };

    const addQuestion = () => {
        // Generate a smaller, safe integer ID
        const newId = Math.floor(Math.random() * 1000000); // This will generate IDs between 0 and 999999
        
        setConfig(prev => ({
            ...prev,
            questions: [
                ...prev.questions,
                {
                    id: newId,
                    type: 'star_rating',
                    text: '',
                    required: true
                }
            ]
        }));
    };

    const updateQuestion = (index, field, value) => {
        const updatedQuestions = [...config.questions];
        updatedQuestions[index] = {
            ...updatedQuestions[index],
            [field]: value
        };
        setConfig(prev => ({ ...prev, questions: updatedQuestions }));
    };

    // const saveConfig = async () => {
    //     try {
    //         await axios.put('/api/reviews/vendor/config', config);
    //         alert('Configuration saved successfully');
    //     } catch (err) {
    //         alert('Error saving configuration');
    //     }
    // };

    const saveConfig = async () => {
        try {
            // Ensure questions have sort_order
            const questionsWithOrder = config.questions.map((q, index) => ({
                ...q,
                sort_order: index
            }));

            await axios.put('/api/reviews/vendor/config', {
                ...config,
                questions: questionsWithOrder
            });
            
            setSaveStatus({
                show: true,
                message: 'Configuration saved successfully',
                type: 'success'
            });
            setTimeout(() => setSaveStatus({ show: false, message: '', type: '' }), 3000);
        } catch (err) {
            setSaveStatus({
                show: true,
                message: 'Error saving configuration',
                type: 'error'
            });
        }
    };

       // question reordering functions
       const moveQuestion = (fromIndex, toIndex) => {
        const updatedQuestions = [...config.questions];
        const [movedItem] = updatedQuestions.splice(fromIndex, 1);
        updatedQuestions.splice(toIndex, 0, movedItem);
        setConfig(prev => ({ ...prev, questions: updatedQuestions }));
    };

    // question deletion
    const deleteQuestion = (index) => {
        const updatedQuestions = config.questions.filter((_, i) => i !== index);
        setConfig(prev => ({ ...prev, questions: updatedQuestions }));
    };

    const renderConfiguration = () => (
        <div className="review-configuration">
            {saveStatus.show && (
                <div className={`save-notification ${saveStatus.type}`}>
                    {saveStatus.message}
                </div>
            )}
            
            <h3>Review Form Configuration</h3>
            <div className="question-list">
                {config.questions.map((question, index) => (
                    <div key={question.id} className="question-item">
                        <div className="question-controls">
                            <button 
                                className="move-btn"
                                onClick={() => moveQuestion(index, index - 1)}
                                disabled={index === 0}
                            >↑</button>
                            <button 
                                className="move-btn"
                                onClick={() => moveQuestion(index, index + 1)}
                                disabled={index === config.questions.length - 1}
                            >↓</button>
                            <button 
                                className="delete-btn-rm"
                                onClick={() => deleteQuestion(index)}
                            >×</button>
                        </div>
                        
                        <select
                            value={question.type}
                            onChange={(e) => updateQuestion(index, 'type', e.target.value)}
                        >
                            <option value="star_rating">Star Rating</option>
                            <option value="text">Text Response</option>
                        </select>
                        
                        <input
                            type="text"
                            placeholder="Question text"
                            value={question.text}
                            onChange={(e) => updateQuestion(index, 'text', e.target.value)}
                        />
                        
                        <label className="required-checkbox">
                            <input
                                type="checkbox"
                                checked={question.required}
                                onChange={(e) => updateQuestion(index, 'required', e.target.checked)}
                            />
                            Required
                        </label>
                    </div>
                ))}
                <button onClick={addQuestion} className="add-question-btn">
                    Add Question
                </button>
            </div>

            <div className="notification-settings-review">
                <h3>Notification Settings</h3>
                <textarea
                    placeholder="Enter email addresses (one per line)"
                    value={config.notification_emails?.join('\n') || ''}
                    onChange={(e) => setConfig(prev => ({
                        ...prev,
                        notification_emails: e.target.value.split('\n').filter(email => email.trim())
                    }))}
                />
            </div>

            <button onClick={saveConfig} className="save-config-btn">
                Save Configuration
            </button>
        </div>
    );

    const renderReviews = () => (
        <div className="reviews-list">
            <div className="reviews-stats">
                <div className="stat-card-review">
                    <h4>Total Reviews</h4>
                    <p>{stats?.total_reviews || 0}</p>
                </div>
                <div className="stat-card-review">
                    <h4>Average Rating</h4>
                    <p>{Number(stats?.average_rating || 0).toFixed(1)} ★</p>
                </div>
                <div className="stat-card-review">
                    <h4>Last 30 Days</h4>
                    <p>{stats?.last_30_days || 0}</p>
                </div>
            </div>
    
            <div className="question-stats">
                <h3>Question Statistics</h3>
                {stats?.questionStats?.map((questionStat) => (
                    <div key={questionStat.question_id} className="question-stat-card">
                        <h4>{questionStat.question_text}</h4>
                        {questionStat.question_type === 'star_rating' ? (
                            <div className="rating-stats">
                                <div className="average-rating">
                                    <span className="rating-value">
                                        {Number(questionStat.average_rating || 0).toFixed(1)} ★
                                    </span>
                                    <span className="response-count">
                                        ({questionStat.response_count} responses)
                                    </span>
                                </div>
                                {questionStat.rating_distribution && (
                                    <div className="rating-distribution">
                                        {Object.entries(questionStat.rating_distribution)
                                            .reverse()
                                            .map(([rating, count]) => (
                                                <div key={rating} className="rating-bar">
                                                    <span className="rating-label">{rating}★</span>
                                                    <div className="rating-bar-container">
                                                        <div 
                                                            className="rating-bar-fill"
                                                            style={{
                                                                width: `${(Number(count) / Number(questionStat.response_count)) * 100}%`
                                                            }}
                                                        />
                                                    </div>
                                                    <span className="rating-count">{count}</span>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="text-response-stats">
                                <p>{questionStat.response_count} text responses</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
    
            <div className="reviews-table">
                {reviews.map(review => (
                    <div key={review.id} className="review-item">
                        <div className="review-header">
                            <div className="reviewer-info">
                                <span className="reviewer-name">
                                    {review.reviewer_name}
                                    {review.is_registered_user && 
                                        <span className="registered-badge">Registered</span>
                                    }
                                </span>
                                {review.reviewer_email && (
                                    <span className="reviewer-email">
                                        <i className="fas fa-envelope"></i> {review.reviewer_email}
                                    </span>
                                )}
                                {review.reviewer_phone && (
                                    <span className="reviewer-phone">
                                        <i className="fas fa-phone"></i> {review.reviewer_phone}
                                    </span>
                                )}
                            </div>
                            <span className="review-date">
                                {new Date(review.created_at).toLocaleDateString()}
                            </span>
                        </div>
                        <div className="review-responses">
                            {review.responses_with_questions?.map((response) => (
                                <div key={response.question_id} className="response-item">
                                    <p className="question-text">{response.question_text}</p>
                                    {response.question_type === 'star_rating' ? (
                                        <div className="stars">{'★'.repeat(Number(response.answer))}</div>
                                    ) : (
                                        <p className="response-text">{response.answer}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );

    if (isLoading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="review-management">
            <nav className="review-nav">
                <button
                    className={activeSection === 'configuration' ? 'active' : ''}
                    onClick={() => setActiveSection('configuration')}
                >
                    Configuration
                </button>
                <button
                    className={activeSection === 'reviews' ? 'active' : ''}
                    onClick={() => setActiveSection('reviews')}
                >
                    Reviews
                </button>
            </nav>

            {activeSection === 'configuration' ? renderConfiguration() : renderReviews()}
        </div>
    );
};

export default ReviewManagement;